@charset "UTF-8";

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
img,
form,
fieldset,
input,
#textNewsarea,
blockquote {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  width: 100%;
  min-width: 1100px;
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu';
  font-size: 9pt;
  text-align: left;
  color: #000000;
  overflow-x: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("static/images/background.jpg");
  background-position: center;
  background-position-y: 0px;
  background-color: #ffffff;
  background-repeat: no-repeat;
}

@font-face {
  font-family: "baseball-font";
  font-weight: bold;
  src: url("static/font/old_school_united_regular.ttf") format("truetype");
  font-style: normal;
}