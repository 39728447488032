.partenaires {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.partenaire img {
    height: 175px;
    margin: 30px;
}