footer {
    margin-top: 125px;
    padding-top: 100px;
    height: 370px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-image: url("../../static/images/footer/footerim.png");
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

#footer_logo_ligue {
    display: flex;
    justify-content: space-around;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}