/* Style the tab content */
@import "react-slideshow-image/dist/styles.css";

.tabContent {
    float: left;
    padding: 15px 30px;
    border: 1px solid #ccc;
    width: 80%;
    border-left: none;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.textNews {
    padding-top: 20px;
    padding-bottom: 40px;
    font-size: 1.3em;
    text-align: justify;
    overflow-y: auto;
    height: 500px;
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
}

.image {
    max-height: 500px;
    max-width: 100%;
    display: flex;
}

.unique {
    margin-bottom: 30px;
}

.parent {
    height: 100%;
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}