#welcome {
    overflow: hidden;
    border-bottom: 2px;
    border-style: solid;
    height: 550px;
    position: relative;
    top: 90px;
}

#welcome-image {
    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
}

#welcome-image #team-image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}


#welcome-logo {
    top: 50px;
    height: 350px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 2;
}

#welcome-text {
    top: 450px;
    width: 100%;
    position: absolute;
    z-index: 2;
}

#welcome-text .bigBandeau {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
}