.toggle {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 20px;
  padding-bottom: 30px;
}

.pdf {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text {
  font-size: 1.5em;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

.inscription_folder {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.closed-inscriptions {
  width: 100%;
  text-align: center;
  font-size: 2em;
  margin-top: 20px;
}

.count-down {
  width: 100%;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  margin-top: 150px;
}
