.contact {
  width: 225px;
  height: 375px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.contactPicture {
  height: 256px;
  overflow: hidden;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(197, 179, 88, 0.7);
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
  font-size: 1.1em;
}

.contact:hover .overlay {
  width: 100%;
}

.contactPicture img {
  width: 100%;
  height: 256px;
  filter: grayscale(100%);
  overflow: hidden;
}

.contactDescription {
  padding: 0 16px 16px 16px;
}

.contactName {
  text-align: center;
  font-family: "menu-font";
  font-size: 1.7em;
  color: black;
  position: absolute;
  overflow: hidden;
  width: 240px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 1;
}

.contactStatus {
  margin-top: 10px;
  margin-bottom: 16px;
  text-align: left;
  font-size: 1.2em;
  color: dimgrey;
  font-weight: bold;
}

.contactInfos {
  text-align: left;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  transition: 0.5s ease;
  font-weight: bold;
}

.button:hover {
  background-color: #c5b358;
  color: black;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  bottom: 100%;
  left: 50%;
  margin-left: -75px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
