/* The actual timeline (the vertical ruler) */
.timeline {
    text-align: justify;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

}

.timeline p {
    background: none;
    margin: 0;
    font-size: 1.15em;
}

.timeline h2 {
    margin-bottom: 10px;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #c5b358;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Container around content */
.container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #dfc12ded;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

/* Place the container to the left */
.timeline_left {
    left: 0;
}

.timeline_left h2 {
    text-align: right;
}

/* Place the container to the right */
.timeline_right {
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.timeline_left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #c5b358ed;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #c5b358;
}

/* Add arrows to the right container (pointing left) */
.timeline_right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #c5b358;
    border-width: 10px 10px 10px 0;
    border-color: transparent #c5b358 transparent transparent;
}

/* Fix the circle for containers on the right side */
.timeline_right::after {
    left: -16px;
}

/* The actual content */
.content {
    padding: 20px 30px;
    background-color: rgb(238, 238, 238);
    position: relative;
    border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

    /* Place the timelime to the left */
    .timeline::after {
        left: 31px;
    }

    /* Full-width containers */
    .container {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .container::before {
        left: 60px;
        border: medium solid #c5b358;
        border-width: 10px 10px 10px 0;
        border-color: transparent #c5b358 transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    .timeline_left::after,
    .timeline_right::after {
        left: 15px;
    }

    /* Make all right containers behave like the left ones */
    .timeline_right {
        left: 0%;
    }
}