#newsContainer {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    height: 1100px;
}

/* Style the tab */
.newsTab {
    float: left;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 20%;
    height: 100%;
}

/* Style the buttons that are used to open the tab content */
.newsTab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-weight: bold;
}

/* Change background color of buttons on hover */
.newsTab button:hover {
    background-color: #c5b358;
}

/* Create an active/current "tab button" class */
.newsTab button.activated {
    background-color: #c5b358;
}