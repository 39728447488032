@font-face {
    font-family: "baseball-font";
    font-weight: bold;
    src: url("../../static/font/old_school_united_regular.ttf") format("truetype");
    font-style: normal;
}

* {
    box-sizing: border-box;
}

.column {
    float: left;
    height: 45px;
    margin-bottom: 30px;
}

.left {
    border-radius: 30% 0px 0px 30%;
}

.right {
    border-radius: 0px 30% 30% 0px;
}

.left,
.right {
    width: 2%;
    background: black;
    border: 5px solid black;
}

.middle {
    width: 96%;
    background: #c5b358;
    border: 5px solid #c5b358;
    font-family: "baseball-font";
    font-size: 3em;
    text-align: center;
    vertical-align: middle;
    line-height: 100%;
}

/* Clear floats after the columns */
.bigBandeau:after {
    content: "";
    display: table;
    clear: both;
}