#bandeau {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-align: left;
    font-family: "baseball-font";
    color: #000000;
    overflow: hidden;
    font-size: 2.8em;
}

#jaune {
    color: #c5b358;
}

#bandeau:after {
    content: "";
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 3px solid black;
}