.fullCalendarsDiv {
    width: 100%;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: 50px;
    align-content: space-around;
}

.fullCalendarUnit {
    width: 20%;
    margin-right: 5px;
    margin-left: 5px;
}

.fullCalendarUnit button {
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
    height: 50px;
    font-size: 1.5em;
}


.button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    transition: 0.5s ease;
    font-weight: bold;
}

.button:hover {
    background-color: #c5b358;
    color: black;
}

.calendarTop {
    margin-top: 50px;
}

.calendarBottom {
    margin-bottom: 50px;
}