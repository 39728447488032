#fullMenu {
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
}

#menu {
    width: 100%;
    display: inherit;
    flex-direction: row-reverse;
}

#menus {
    color: white;
    width: 120px;
    justify-content: center;
    align-items: center;
    font-family: "menu-font";
    font-size: 1.2em;
    text-decoration: none;

}

#menus:hover {
    background-color: #c5b358;
    color: black;
    cursor: pointer;
}

#menus:hover #subMenus {
    display: block;
    text-align: center;
}



#mainMenu {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-decoration: inherit;
    color: inherit;
}

#subMenus {
    display: none;
    font-family: inherit;
    text-decoration: inherit;
    color: white;
    background-image: url("../../static/images/menuim.jpg");
    font-size: 0.75em;
    width: 150px;
    height: auto;
    padding-top: 3px;
    padding-bottom: 3px;
    border-top: 1px solid #2a2b2b;
}

#menus a {
    font-family: inherit;
    text-decoration: inherit;
    color: white;
}

#menus Link {
    font-family: inherit;
    text-decoration: inherit;
    color: white;
}

#subMenus:hover {
    color: black;
    background-color: #c5b358;
    background-image: none;
}

#subMenus:hover a {
    color: black;
}

#header {
    height: 90px;
    width: 100%;
    background-image: url("../../static/images/menuim.jpg");
    border-bottom: 3px solid #bfbfbf;
    position: fixed;
    z-index: 999;
}

@font-face {
    font-family: "menu-font";
    src: url("../../static/font/DaysOne-Regular.ttf") format("truetype");
    font-style: normal;
}